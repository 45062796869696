import React, {useEffect, useState, LazyExoticComponent, Suspense} from 'react';
import {BrowserRouter as Router, matchPath, Route, Switch, useLocation} from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { TextsContext } from 'videokube-front-library/dist/TextsContext';
import { removeParamsFromParamString, getReactAppGTM, showPrivacyPolicy, showFaq, showContactPage, showWithdrawal, showImprint } from 'videokube-front-library/dist/videokubeUtils';
import { useFile, useText } from 'videokube-front-library/dist/hooks';
import { Navbar } from 'videokube-front-library/dist/Navbar';
import { NavbarTranslationComponent } from 'videokube-front-library/dist/Navbar/TranslationComponents';
import foundSolutionLogin from 'videokube-front-library/dist/Solutions/login';
import foundSolution from 'videokube-front-library/dist/Solutions/index';
import { Footer } from 'videokube-front-library/dist/Footer';
import { flexibleGetEnv, getCurrentLang } from 'videokube-front-library/dist/utils';
import {
  DefaultTheme,
  FooterStyle,
  getTheme,
  navbarStyle,
  translationComponent
} from './theme';
import zipObject from 'lodash/zipObject';
import {IntlProvider} from 'react-intl';
import {AppState, finalReducer} from './store';
import {createStore} from 'redux';
import {Provider, useSelector} from "react-redux";
import {InterfaceConfiguration, IText, JwtPayload} from 'videokube-front-library/dist/types';
import {SnackbarProvider} from 'material-ui-snackbar-provider';
import './loader.css';
import './App.css';
import {Loader} from './components/Loader';
import ScrollToTop from './components/ScrollToTop';
import { getFiles, getCategories, getTexts, getInterfaceConfiguration, getChannels, pingMe, getEvents } from 'videokube-front-library/dist/api';


const About = React.lazy(() => import('./pages/About'));
const Homepage = React.lazy(() => import('./pages/Homepage'));
const Topics = React.lazy(() => import('./pages/Topics/Topics'));
const Category = React.lazy(() => import('./pages/Category/Category'));
const Vod = React.lazy(() => import('./pages/Videos/Vod'));
const Tv = React.lazy(() => import('./pages/Videos/Tv'));
const LoginPage = React.lazy(() => import('./pages/LoginPage/LoginPage'));
const RedirectLandingPage = React.lazy(() => import('./pages/LoginPage/RedirectLandingPage'));
const Account = React.lazy(() => import('./pages/Account/Account'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Schedule = React.lazy(() => import('./pages/Schedule/Schedule'));
const TermsConditions = React.lazy(() => import('./pages/TermsConditions'));
const ThankYouPage = React.lazy(() => import('./pages/ThankYou'));
const FraudFallback = React.lazy(() => import('./pages/FraudFallback'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'));
const RedirectStatusPage = React.lazy(() => import('./pages/RedirectStatus'));
const LoginPageWithoutPassword = React.lazy(() => import('./pages/LoginPage/LoginPageWithoutPassword'));
const LoginPageNoPasswordNoCheck = React.lazy(() => import('./pages/LoginPage/LoginPageNoPasswordNoCheck'));
const LoginPageForThreeAnet = React.lazy(() => import('./pages/LoginPage/LoginPageForThreeAnet'));
const LoginPageForKorek = React.lazy(() => import('./pages/LoginPage/LoginPageForKorek'));
const FAQ = React.lazy(() => import('./pages/FAQ'));
const Withdrawal = React.lazy(() => import('./pages/Withdrawal'));
const Imprint = React.lazy(() => import('./pages/Imprint'));

const store = createStore(
  finalReducer,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
);

const dispatch = store.dispatch;

const getAndSetTexts = async () => {
  const texts = await getTexts();
  dispatch({ type: 'SET_TEXTS', data: texts });
};

const getAndSetInterfaceConfiguration = async () => {
  const interfaceConfiguration = await getInterfaceConfiguration();
  dispatch({
    type: 'SET_INTERFACE_CONFIGURATION',
    data: interfaceConfiguration,
  });
};

const handleChannelsAndTvLink = async () => {
  try{
    const channels = await getChannels();
    dispatch({ type: 'SET_CHANNELS', data: channels });
  } catch(e){
    return;
  }

};

const getAndSetEvents = async () => {
  const events = await getEvents();
  let sortEvents = events.sort((a,b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());
  dispatch({ type: 'SET_EVENTS', data: sortEvents });

}

const getAndSetFiles = async () => {
  const files = await getFiles();
  dispatch({ type: 'SET_FILES', data: files });
};

const checkHeader = () => {
  removeParamsFromParamString(window.location.search, ["mhe", "msisdnEncrypted"]);
}

const autoLogin = async () => {
  let msisdn = await foundSolution.getHe()
  if (msisdn){
    try {
      const jwtPayload = await foundSolutionLogin.autoLogin(msisdn);
      dispatch({ type: "SET_JWT_PAYLOAD", data: jwtPayload });
      return;
    } catch (exception) {
      return ;
    }
  }

  pingMe().then(aPayload => {
    if (!aPayload) {
      return;
    }
    dispatch({ type: 'SET_JWT_PAYLOAD', data: aPayload });
  }).catch(error => {
    //We catch the error : the user is simply not connected!
  })
}

const init = async () => {
  getReactAppGTM();
  checkHeader();
  autoLogin();

  const arrayOfPromises: Array<any> = [
    getAndSetInterfaceConfiguration(),
    getAndSetTexts(),
    getCategories().then((categories) =>
      dispatch({ type: 'SET_CATEGORIES', data: categories }),
    ),
    handleChannelsAndTvLink(),
    getAndSetFiles(),
    getAndSetEvents()
  ];
  try {
    await Promise.all(arrayOfPromises);
    dispatch({ type: 'SET_READY', data: null });
  } catch (e) {
    //TODO: we should have an alert component ?
    console.log('ERROR while loading necessary data', e);
    //We don't set the page as ready... So it stays as "Loading" for ever. Intentional!
  }
};
init();

interface IRoute {
  name: string;
  component: string;
  path: string;
  withoutLayout?: boolean;
  allowAnonymous?: boolean;
  onlySubscriberUser?: boolean;
}

export let navbarLightOnLanding = true;
const routesDb: IRoute[] = [
  { name: 'Contact', path: '/contact', component: 'Contact', allowAnonymous: true },
  { name: 'Withdrawal', path: '/right-of-withdrawal', component: 'Withdrawal', allowAnonymous: true },
  { name: 'Imprint', path: '/imprint', component: 'Imprint', allowAnonymous: true },
  { name: 'About', path: '/about', component: 'About', allowAnonymous: true },
  { name: 'LoginPage', path: '/login', component: foundSolutionLogin.getAppropriatePage(), withoutLayout: true, allowAnonymous: true },
  //{ name: 'LandingPage', path: '/landing', component: foundSolution.getAppropriatePage(), allowAnonymous: true, withoutLayout: true},
  { name: 'Homepage', path: '/', component: 'Homepage', allowAnonymous: true},
  { name: 'Topics', path: '/replay-center', component: 'Topics', onlySubscriberUser: true },
  { name: 'Category', path: '/category/:slug', component: 'Category', onlySubscriberUser: true },
  { name: 'Vod', path: '/vod/:slug', component: 'Vod', onlySubscriberUser: true },
  { name: 'Tv', path: '/tv', component: 'Tv', onlySubscriberUser: true },
  { name: 'Garbage', path: '/garbage', component: 'Home' },
  { name: 'TermsConditions', path: '/terms-and-conditions', component: 'TermsConditions', allowAnonymous: true},
  { name: 'Schedule', path: '/schedule', component: 'Schedule', onlySubscriberUser: true },
  { name: 'Account', path: '/account', component: 'Account' },
  { name: 'ThankYou', path: '/thank-you', component: 'ThankYouPage', allowAnonymous: true},
  { name: 'Blocked', path: '/blocked', component: 'FraudFallback', allowAnonymous: true},
  { name: 'PrivacyPolicy', path: '/privacy-policy', component: 'PrivacyPolicy', allowAnonymous: true },
  { name: 'RedirectStatus', path: '/redirect-status', component: 'RedirectStatusPage', allowAnonymous: true},
  { name: 'FAQ', path: '/faq', component: 'FAQ', allowAnonymous: true },
];

const linksLeft = [
  {
    slug: '/tv',
    title: 'live',
  },
  {
    slug: '/replay-center',
    title: 'topics',
  },
];

//This create a fake asynchronous routing (for instance coming from a backend server)
const getRoutesDb = async () => {
  return routesDb;
};

function App() {
  const [routes, setRoutes] = useState<IRoute[]>([]);

  const ready = useSelector<AppState, boolean>((state) => state.app.ready);
  const texts = useSelector<AppState, IText[]>((state) => state.app.texts);
  const jwtPayload = useSelector<AppState, JwtPayload>((state) => state.app.jwtPayload);

  const location = useLocation();

  const currentRoute = routes.find(
    route => matchPath(location.pathname,{exact:true,path:route.path})
  )

  const interfaceConfiguration = useSelector<AppState, InterfaceConfiguration>(
    (state) => state.app.interfaceConfiguration,
  );

  let theme = DefaultTheme;

  if(interfaceConfiguration){
    theme = getTheme(interfaceConfiguration);
  }

  useEffect(() => {
    getRoutesDb().then(someRoutes => setRoutes(someRoutes));
  }, []);

  const favicon = useFile("favicon");
  let link: any = document.querySelector('link[rel="icon"]');
  if(favicon && link){
    link.href = favicon.file;
  }

  const metaTitle = useText("meta_title");
  if(metaTitle && document){
    document.title = metaTitle.value;
  }

  const textsKeyValue = zipObject(
    texts.map(x => x.key),
    texts.map(x => x.value),
  );
  const textsKeyValueObject = zipObject(
    texts.map(x => x.key),
    texts,
  );

  let loggedInUser:string | null = null;
  let subscriberUser:boolean | null = null;

  if(jwtPayload){
    loggedInUser = jwtPayload.username;
    subscriberUser = jwtPayload.user.activeSubscription;
  }

  const logo = useFile("logo");

  if (!ready) {
    return(
      <Loader />
    );
  }

  if(!logo){
    return (<div>Logo not found</div>);
  }

  let forgottenPassword: Nullable<JSX.Element> = null;

  let linksFooter = [
    {
      slug: '/terms-and-conditions',
      title: 'footer_terms_and_conditions_title',
    },
    {
      slug: '/about',
      title: 'footer_about_us_title',
    }
  ];

  if(showWithdrawal()) {
    linksFooter.unshift(
      {
        slug: '/right-of-withdrawal',
        title: 'footer_withdrawal_title',
      }
    )
  }

  if(showImprint()) {
    linksFooter.unshift(
      {
        slug: '/imprint',
        title: 'footer_imprint_title',
      }
    )
  }

  if(showPrivacyPolicy()) {
    linksFooter.unshift(
      {
        slug: '/privacy-policy',
        title: 'footer_privacy_policy_title',
      }
    )
  }

  if(showContactPage()) {
    linksFooter.unshift(
      {
        slug: '/contact',
        title: 'footer_contact_title',
      }
    )
  }

  if(showFaq()) {
    linksFooter.unshift(
      {
        slug: '/faq',
        title: 'footer_faq_title',
      }
    )
  }

  const switchStuff = routes.map(aRoute => {
    //let withLayout = !aRoute.withoutLayout;
    let displayNavbar = !navbarLightOnLanding;
    let displayFooter = !aRoute.withoutLayout;
    const CurrentComponent = componentsDb[aRoute.component];

    let coreElement =  <CurrentComponent />

    if ((currentRoute?.onlySubscriberUser && subscriberUser) || (loggedInUser && !currentRoute?.onlySubscriberUser) || currentRoute?.allowAnonymous ){
      displayNavbar = !aRoute.withoutLayout;
      displayFooter = !aRoute.withoutLayout;
    } else {
      if (foundSolution.getAppropriatePage() === "RedirectLandingPage") {
        window.location.href = foundSolution.getRedirectUrl();
        return(
          <Loader />
        );
      }
      window.location.href = window.location.origin + "/landing/";
    }

    return (
      <Route key={aRoute.path} exact path={aRoute.path}>
      <Suspense fallback={<Loader />}>
        {(displayNavbar) &&
          <div>
            <Navbar
              style={navbarStyle(theme)}
              logo={logo.file+"?size=original"}
              linksLeft={linksLeft}
              linksRight={[]}
              login
              loggedInUser={loggedInUser}
              forgottenPassword={forgottenPassword}
              useLoginPageUrl={"/login"}
              langButton={true}
              translationComponent={<NavbarTranslationComponent style={translationComponent(theme)} />}
            />
          </div>
        }
        {coreElement}
        {(displayFooter) &&
          <div>
            <Footer links={linksFooter} style={FooterStyle(theme)} />
          </div>
        }
      </Suspense>
      </Route>
    );
  });

  return (
    <SnackbarProvider SnackbarProps={{ autoHideDuration: 4000 }}>
      <TextsContext.Provider value={textsKeyValueObject}>
        <IntlProvider locale={getCurrentLang()} messages={textsKeyValue}>
          <MuiThemeProvider theme={theme}>
            <ScrollToTop />
            <div className="App" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', direction: ["ar","ur"].includes(getCurrentLang()) ? 'rtl' : 'ltr' }}> <Switch>{switchStuff}</Switch> </div>
          </MuiThemeProvider>
        </IntlProvider>
      </TextsContext.Provider>
    </SnackbarProvider>
  );
}



const componentsDb: { [id: string]: LazyExoticComponent<() => JSX.Element> } = {
  About,
  Homepage,
  Topics,
  Category,
  Vod,
  Tv,
  LoginPage,
  RedirectLandingPage,
  Account,
  Contact,
  Schedule,
  TermsConditions,
  ThankYouPage,
  FraudFallback,
  PrivacyPolicy,
  RedirectStatusPage,
  LoginPageWithoutPassword,
  LoginPageNoPasswordNoCheck,
  LoginPageForThreeAnet,
  LoginPageForKorek,
  FAQ,
  Withdrawal,
  Imprint
};
const FinalApp = () => <Provider store={store}>
  <Router basename={flexibleGetEnv("REACT_APP_BASENAME")!}>
    <App />
  </Router>
</Provider>

export default FinalApp;
